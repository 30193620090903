<template>
  <TileContent :icon="icon" :title="title" data-cy="wyltt">
    <template #content>
      <RichText
        v-if="description"
        :html="description"
        data-cy="wyltt__description"
      />
    </template>
  </TileContent>
</template>

<script lang="ts">
import TileContent from "atlas/src/components/TileContent/TileContent.vue";
import Vue, { PropType } from "vue";
import { WYLTTProps } from "./Props";
import RichText from "~/components/RichText/RichText.vue";

export default Vue.extend({
  name: "WYLTT",
  components: {
    TileContent,
    RichText,
  },
  props: {
    description: {
      type: String as PropType<WYLTTProps["description"]>,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      icon: "love-trip",
      title: "Why you'll love this trip",
    };
  },
});
</script>
