<template>
  <Tile
    class="tile--text-left tile--small"
    :title="title"
    :link="$link.create(link)"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import { TileLinkProps } from "./Props";

export default Vue.extend({
  name: "TileLink",
  components: { Tile },
  props: {
    title: {
      type: String as PropType<TileLinkProps["title"]>,
      required: true,
    },
    link: {
      type: String as PropType<TileLinkProps["link"]>,
      required: true,
    },
  },
});
</script>
