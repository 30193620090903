<template>
  <Accordion
    description="Trip Itinerary Day Details"
    data-cy="trip-itinerary"
    :toggleable="true"
    :toggleable-default="true"
    @toggle-accordion="toggle"
  >
    <div v-if="showTitle" slot="title">
      <h3>Itinerary</h3>
    </div>
    <div slot="content">
      <TripItineraryDay
        v-for="(tripItineraryDay, index) in itinerary"
        :key="index"
        :tripItineraryDay="tripItineraryDay"
        :expanded="expanded"
        data-cy="trip-itinerary-day"
      />
    </div>
  </Accordion>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Accordion from "atlas/src/components/Accordion/Accordion.vue";
import TripItineraryDay from "../TripItineraryDay/TripItineraryDay.vue";
import { TripItineraryDayProps } from "../TripItineraryDay/Props";

type Data = {
  expanded: boolean;
};

export default Vue.extend({
  name: "TripItinerary",
  components: { Accordion, TripItineraryDay },

  props: {
    showTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    itinerary: {
      type: Array as PropType<TripItineraryDayProps[]>,
      required: true,
    },
  },
  data(): Data {
    return {
      expanded: false,
    };
  },

  methods: {
    toggle(expanded: boolean) {
      this.expanded = expanded;
    },
  },
});
</script>
