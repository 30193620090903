<template>
  <div
    :class="
      (type == 'default'
        ? 'trip-summary'
        : 'trip-summary trip-summary--horizontal') +
      ' l-grid u-padding-top--0 lg:u-padding-top--2 u-padding-bottom--0 lg:u-padding-bottom--2 u-padding-right--0 lg:u-padding-right--2 u-padding-left--0 lg:u-padding-left--2 lg:u-margin-bottom--2 '
    "
    data-cy="trip-summary"
  >
    <div
      v-if="map"
      :class="'trip-summary__image-grid l-grid__cell ' + imageClassNames"
    >
      <Imagery
        class="trip-summary__map u-margin-bottom--0-5 lg:u-margin-bottom--1"
        data-cy="trip-summary__map"
        :lazy-src="map.src"
        :lazy-srcset="map.srcSet"
        :background-color="map.backgroundColor"
        :alt="map.alt"
        :lazy-load="false"
      />
    </div>

    <div
      :class="
        'trip-summary__dictionary-grid l-grid__cell ' +
        dictionaryGridParentCellClassNames
      "
      data-cy="trip-summary__dictionary-grid"
    >
      <div :class="'l-grid ' + dictionaryGridClassNames">
        <dl :class="'horizontal l-grid__cell ' + dictionaryListClassNames">
          <template v-if="startLocation.city && startLocation.country">
            <dt>Start</dt>
            <dd>
              {{ startLocation.city + ", " + startLocation.country }}
            </dd>
          </template>
          <template v-if="finishLocation.city && finishLocation.country">
            <dt>Finish</dt>
            <dd>
              {{ finishLocation.city + ", " + finishLocation.country }}
            </dd>
          </template>
          <template v-if="productTheme.length > 0">
            <dt>Theme</dt>
            <dd>
              {{ productTheme.join(", ") }}
            </dd>
          </template>
          <template v-if="destinations.length > 0">
            <dt>Destinations</dt>
            <dd>
              <span
                v-for="(link, index) in destinations"
                :key="index"
                class="trip-summary__destination"
                ><Link :href="link?.href" :aria-label="link?.text">{{
                  link?.text?.trim()
                }}</Link
                ><span v-if="index != destinations.length - 1">{{
                  ", "
                }}</span></span
              >
            </dd>
          </template>
          <template v-if="physicalRating">
            <dt>Physical rating</dt>
            <dd>
              <Rating :rating="physicalRating" type="rectangle"></Rating>
              <Popper
                :show-popper="showPhysicalRatingInfo"
                data-cy="trip-summary__physical-rating-popper"
                @hide="togglePhysicalRatingInfo(false)"
              >
                <template slot="reference">
                  <span @click="togglePhysicalRatingInfo(true)">
                    <Icon
                      class="trip-summary__physical-rating-popper-trigger"
                      name="info-circle-outline"
                    />
                  </span>
                </template>
                <template slot="content"
                  >Indicates how physically exerting a trip is, and how fit you
                  will need to be to enjoy it.
                  <Link href="/about/physical-rating"
                    >More info on physical ratings</Link
                  ></template
                >
              </Popper>
            </dd>
          </template>
        </dl>
        <dl
          :class="
            'u-margin-top--1 horizontal l-grid__cell ' +
            dictionaryListClassNames
          "
        >
          <template v-if="productStyle.length > 0">
            <dt>Style</dt>
            <dd>
              {{ productStyle.join(", ") }}
            </dd>
          </template>
          <template v-if="ages.min || ages.max">
            <dt>Ages</dt>
            <dd>
              <template v-if="ages.min"> Min {{ ages.min }}</template>
              <template v-if="ages.min && isMaxExist(ages.max)">-</template>
              <template v-if="isMaxExist(ages.max)"
                >Max {{ ages.max }}</template
              >
            </dd>
          </template>
          <template v-if="groupSize.min || groupSize.max">
            <dt>Group size</dt>
            <dd>
              <template v-if="groupSize.min"> Min {{ groupSize.min }}</template>
              <template v-if="groupSize.min && isMaxExist(groupSize.max)"
                >-</template
              >
              <template v-if="isMaxExist(groupSize.max)"
                >Max {{ groupSize.max }}</template
              >
            </dd>
          </template>
          <template v-if="formattedValidity && !hideEffectiveDates">
            <dt>Validity</dt>
            <dd>
              {{ formattedValidity }}
            </dd>
          </template>
          <template v-if="tripCode">
            <dt>Trip code</dt>
            <dd class="u-margin-bottom--1">
              {{ tripCode }}
            </dd>
          </template>
        </dl>
      </div>
    </div>
    <div
      v-if="hasCarbonCalculationSlot"
      data-cy="carbonCalculation-slot"
      class="
        l-grid__cell l-grid__cell--12-col
        u-margin-bottom--1
        u-margin-top--1
      "
    >
      <slot name="carbonCalculation"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import Rating from "atlas/src/components/Rating/Rating.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import Popper from "atlas/src/components/Popper/Popper.vue";
import dayjs, { extend } from "dayjs";
import utc from "dayjs/plugin/utc.js";
import { MetaInfo } from "vue-meta";
import { TripSummaryProps } from "./Props";
import Link from "~/components/Link/Link.vue";

extend(utc);

type Data = {
  showPhysicalRatingInfo: boolean;
};

export default Vue.extend({
  name: "TripSummary",
  components: {
    Imagery,
    Rating,
    Icon,
    Popper,
    Link,
  },
  props: {
    type: {
      required: false,
      default: "default",
      type: String as PropType<TripSummaryProps["type"]>,
    },
    map: {
      required: true,
      type: Object as PropType<TripSummaryProps["map"]>,
    },
    startLocation: {
      required: true,
      type: Object as PropType<TripSummaryProps["startLocation"]>,
    },
    finishLocation: {
      required: true,
      type: Object as PropType<TripSummaryProps["finishLocation"]>,
    },
    productTheme: {
      required: true,
      type: Array as PropType<TripSummaryProps["productTheme"]>,
    },
    destinations: {
      required: true,
      type: Array as PropType<TripSummaryProps["destinations"]>,
    },
    physicalRating: {
      required: true,
      type: Number as PropType<TripSummaryProps["physicalRating"]>,
    },
    productStyle: {
      required: true,
      type: Array as PropType<TripSummaryProps["productStyle"]>,
    },
    ages: {
      required: true,
      type: Object as PropType<TripSummaryProps["ages"]>,
    },
    groupSize: {
      required: true,
      type: Object as PropType<TripSummaryProps["groupSize"]>,
    },
    validity: {
      required: true,
      type: Object as PropType<TripSummaryProps["validity"]>,
    },
    tripCode: {
      required: true,
      type: String as PropType<TripSummaryProps["tripCode"]>,
    },
    hideEffectiveDates: {
      required: false,
      default: false,
      type: Boolean as PropType<TripSummaryProps["hideEffectiveDates"]>,
    },
  },
  data(): Data {
    return {
      showPhysicalRatingInfo: false,
    };
  },
  head(): MetaInfo {
    return {
      link: this.map
        ? [
            {
              rel: "preload",
              as: "image",
              href: this.map?.src,
              imagesrcset: this.createSrcSet(this.map?.srcSet),
              imagesizes: "100vw",
              fetchpriority: "high",
            },
          ]
        : [],
    };
  },
  computed: {
    formattedValidity() {
      if (this.validity && this.validity.from && this.validity.to) {
        return (
          dayjs.utc(this.validity.from).format("DD MMM YYYY") +
          " - " +
          dayjs.utc(this.validity.to).format("DD MMM YYYY")
        );
      }
      return undefined;
    },
    dictionaryListClassNames() {
      if (this.type === "horizontal") {
        return "l-grid__cell--12-col l-grid__cell--4-col-tablet";
      }
      return "l-grid__cell--12-col l-grid__cell--4-col-tablet l-grid__cell--6-col-desktop";
    },
    imageClassNames() {
      if (this.type === "horizontal") {
        return "l-grid__cell--12-col l-grid__cell--8-col-desktop";
      }
      return "l-grid__cell--12-col";
    },
    dictionaryGridParentCellClassNames() {
      if (this.type === "horizontal") {
        return "l-grid__cell--12-col l-grid__cell--4-col-desktop";
      }
      return "l-grid__cell--12-col";
    },
    dictionaryGridClassNames() {
      if (this.type === "horizontal") {
        return "l-grid--no-spacing";
      }
      return "";
    },
    hasCarbonCalculationSlot() {
      return (
        Array.isArray(this.$slots.carbonCalculation) &&
        this.$slots.carbonCalculation.length > 0
      );
    },
  },
  methods: {
    togglePhysicalRatingInfo(show: boolean) {
      this.showPhysicalRatingInfo = show;
    },
    createSrcSet(srcSet?: { src: string; size: string }[]): string | undefined {
      return srcSet?.map(({ src, size }) => `${src} ${size}`).join(", ");
    },
    isMaxExist(value?: number) {
      return value && value !== 99;
    },
  },
});
</script>
<style lang="scss">
@import "./trip-summary";
</style>
