<template>
  <AnchorNavigation v-if="menuItems.length > 1" :menu-items="menuItems" />
</template>

<script lang="ts">
import Vue from "vue";
import AnchorNavigation from "atlas/src/components/AnchorNavigation/AnchorNavigation.vue";
import { SubNavigationMenuItemProps } from "./Props";

export default Vue.extend({
  name: "SubNavigation",
  components: {
    AnchorNavigation,
  },
  data() {
    return {
      menuItemsAtPageLoad: [] as SubNavigationMenuItemProps[],
    };
  },
  computed: {
    menuItems(): SubNavigationMenuItemProps[] {
      const componentUpdatedAt = this.$store.getters["app/componentUpdatedAt"];

      if (componentUpdatedAt) {
        return this.getAnchorTags();
      }

      return this.menuItemsAtPageLoad;
    },
  },
  mounted() {
    this.menuItemsAtPageLoad = this.getAnchorTags();
  },
  methods: {
    getAnchorTags(): SubNavigationMenuItemProps[] {
      const anchorTags = document.querySelectorAll("[data-anchor-text]");
      const menuItems: SubNavigationMenuItemProps[] = [];

      anchorTags.forEach((element) => {
        const id = element.getAttribute("id");
        const anchorText = element.getAttribute("data-anchor-text");

        if (id && anchorText) {
          menuItems.push({
            id,
            anchorText,
          });
        }
      });

      return menuItems;
    },
  },
});
</script>
