<template>
  <div data-cy="terms-and-conditions">
    <template v-for="(termAndCondition, index) in termsAndConditions">
      <Accordion
        v-if="termAndCondition.displayName"
        :key="index"
        :description="termAndCondition.displayName"
        data-cy="terms-condition-accordion"
      >
        <div slot="content">
          <AccordionItem>
            <h2
              slot="title"
              class="headline--5 u-margin-top--0 u-margin-bottom--0"
            >
              {{ termAndCondition.displayName }}
            </h2>
            <div slot="content">
              <JsonRichText
                v-if="termAndCondition?.description"
                v-bind="termAndCondition?.description"
              />
            </div>
          </AccordionItem>
        </div>
      </Accordion>
    </template>
    <hr class="u-margin-top--0" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Accordion from "atlas/src/components/Accordion/Accordion.vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";
import { TermsAndConditionsProps } from "./Props";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

export default Vue.extend({
  name: "TermsAndConditions",
  components: {
    Accordion,
    AccordionItem,
    JsonRichText,
  },
  props: {
    termsAndConditions: {
      type: Array as PropType<TermsAndConditionsProps[]>,
      required: true,
    },
  },
});
</script>
