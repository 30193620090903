<template>
  <div data-cy="trip-inclusions">
    <Tile
      v-if="meals?.length"
      :title="'Meals'"
      :icon="'fork-knife'"
      class="
        tile--horizontal tile--text-left tile--small tile--unboxed
        u-margin-bottom--0-5
        u-background-color--sand
      "
      data-cy="trip-inclusions-meals"
    >
      <p class="u-margin-bottom--0">{{ joinArrayString(meals) }}</p>
    </Tile>

    <Tile
      v-if="transport?.length"
      :title="'Transport'"
      :icon="'bus-outline'"
      class="
        tile--horizontal tile--text-left tile--small tile--unboxed
        u-margin-bottom--0-5
        u-background-color--sand
      "
      data-cy="trip-inclusions-transport"
    >
      <p class="u-margin-bottom--0">{{ joinArrayString(transport) }}</p>
    </Tile>

    <Tile
      v-if="accommodations?.length"
      :title="'Accommodation'"
      :icon="'hotel-outline'"
      class="
        tile--horizontal tile--text-left tile--small tile--unboxed
        u-margin-bottom--0-5
        u-background-color--sand
      "
      data-cy="trip-inclusions-accommodation"
    >
      <p class="u-margin-bottom--0">{{ joinArrayString(accommodations) }}</p>
    </Tile>

    <template v-if="styleInclusions?.length">
      <template v-for="(item, index) in styleInclusions">
        <Tile
          v-if="item?.inclusions?.length"
          :key="index"
          :title="item.title"
          :icon="`${item.icon}`"
          class="
            tile--horizontal tile--text-left tile--small tile--unboxed
            u-margin-bottom--0-5
            u-background-color--sand
          "
          data-cy="trip-inclusions-style"
        >
          <ul class="u-padding-left--1 u-margin-top--0">
            <li
              v-for="(inclusion, inclusionIndex) in item.inclusions"
              :key="inclusionIndex"
            >
              {{ inclusion }}
            </li>
          </ul>
        </Tile>
      </template>
    </template>

    <Tile
      v-if="includedActivities?.length"
      title="Activities"
      icon="experiences"
      class="
        tile--horizontal tile--text-left tile--small tile--unboxed
        u-margin-bottom--0-5
        u-background-color--sand
      "
      data-cy="trip-inclusions-activities"
    >
      <ul class="u-padding-left--1 u-margin-top--0">
        <li
          v-for="(
            includedActivity, includedActivityIndex
          ) in includedActivities"
          :key="includedActivityIndex"
        >
          {{ includedActivity }}
        </li>
      </ul>
    </Tile>

    <Tile
      v-if="optionalActivities?.length"
      :title="'Add on activities'"
      :icon="'plus'"
      class="
        tile--horizontal tile--text-left tile--small tile--unboxed
        u-margin-bottom--0-5
        u-background-color--sand
      "
      data-cy="trip-inclusions-optional-activities"
    >
      <ul class="u-padding-left--1 u-margin-top--0">
        <li
          v-for="(
            optionalActivity, optionalActivityIndex
          ) in optionalActivities"
          :key="optionalActivityIndex"
        >
          {{ optionalActivity }}
        </li>
      </ul>
    </Tile>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import { TripInclusionsProps } from "./Props";
export default Vue.extend({
  name: "TripInclusions",
  components: {
    Tile,
  },
  props: {
    accommodations: {
      type: Array as PropType<TripInclusionsProps["accommodations"]>,
      required: false,
      default: undefined,
    },
    transport: {
      type: Array as PropType<TripInclusionsProps["transport"]>,
      required: false,
      default: undefined,
    },
    meals: {
      type: Array as PropType<TripInclusionsProps["meals"]>,
      required: false,
      default: undefined,
    },
    includedActivities: {
      type: Array as PropType<TripInclusionsProps["includedActivities"]>,
      required: false,
      default: undefined,
    },
    optionalActivities: {
      type: Array as PropType<TripInclusionsProps["optionalActivities"]>,
      required: false,
      default: undefined,
    },
    styleInclusions: {
      type: Array as PropType<TripInclusionsProps["styleInclusions"]>,
      required: false,
      default: undefined,
    },
  },
  methods: {
    joinArrayString(arrayString: string[]): string {
      return arrayString?.join(", ");
    },
  },
});
</script>
