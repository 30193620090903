<template>
  <Alert type="warning" data-cy="trip-excluded-sales-region-alert">
    <div slot="content">
      Unfortunately this tour isn’t available for sale in your country or
      region.
    </div>
  </Alert>
</template>

<script lang="ts">
import Vue from "vue";
import Alert from "atlas/src/components/Alert/Alert.vue";

export default Vue.extend({
  name: "TripExcludedSalesRegionAlert",
  components: {
    Alert,
  },
});
</script>
