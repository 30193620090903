<template>
  <Page v-bind="page">
    <template #alerts>
      <ErrorBoundary
        v-if="travelAlert"
        class="l-container sm:u-margin-top--1 u-margin-top--0-5"
      >
        <TravelAlert v-bind="travelAlert" />
      </ErrorBoundary>
      <ErrorBoundary
        v-if="isExcludedSalesRegion"
        :class="computedAlertSpacingClass"
      >
        <TripExcludedSalesRegionAlert />
      </ErrorBoundary>
    </template>
    <ErrorBoundary>
      <TripSnapshot
        :duration="duration"
        :tagline="tagline"
        :themes="themes"
        :review-aggregate="reviewAggregate"
        :from-price-discount="fromPriceDiscount"
        :product-code="productCode"
        :product-name="page?.banner?.title"
        :excluded-sale-regions="excludedSaleRegions"
      />
    </ErrorBoundary>
    <ErrorBoundary v-if="gallery?.images.length">
      <Gallery class="l-container" v-bind="gallery" />
    </ErrorBoundary>
    <SubNavigation class="u-margin-top--4" />
    <ErrorBoundary
      v-if="introduction"
      id="trip-summary"
      data-anchor-text="Trip summary"
      class="l-container l-container--small u-margin-top--2 sm:u-margin-top--6"
    >
      <div
        v-if="themeProductMessaging?.length"
        class="
          trip-page__theme-product-messaging
          u-margin-top--3
          u-margin-bottom--3
          xl:u-margin-bottom--4 xl:u-margin-top--4
        "
      >
        <ThemeProductMessaging
          v-for="(productTheme, index) in themeProductMessaging"
          v-bind="productTheme"
          :key="index"
        />
      </div>
      <Introduction
        v-if="introduction && introduction.longDescription"
        :long-description="introduction?.longDescription"
      />
    </ErrorBoundary>
    <ErrorBoundary v-if="dealPromotion" class="l-container">
      <DealPromotionCard
        v-bind="dealPromotion"
        class="u-margin-top--4 sm:u-margin-top--6"
      />
    </ErrorBoundary>
    <ErrorBoundary
      v-if="tripSummary"
      id="trip-overview"
      class="
        l-container
        u-margin-bottom--3
        sm:u-margin-bottom--6
        u-margin-top--4
        sm:u-margin-top--6
      "
      data-anchor-text="Trip overview"
    >
      <h2 v-if="tripSummary" class="u-padding-bottom--1-5">Trip overview</h2>
      <TripSummary
        v-bind="tripSummary"
        :hide-effective-dates="true"
        type="horizontal"
      >
        <template v-if="showCarbonCalculation" slot="carbonCalculation">
          <CarbonCalculation v-bind="carbonCalculation" />
        </template>
      </TripSummary>
    </ErrorBoundary>

    <div
      v-if="uniqueSellingPoints?.length >= 3"
      data-cy="unique-selling-point-list"
      class="l-container u-margin-bottom--3 sm:u-margin-bottom--6"
    >
      <UniqueSellingPointList :uniqueSellingPoints="uniqueSellingPoints" />
    </div>

    <Separator
      v-if="(wyltt && wyltt.description) || (ittrfy && ittrfy.description)"
      data-cy="separator__top"
    />
    <ErrorBoundary class="l-container">
      <WYLTT
        id="WYLTT"
        class="
          u-margin-top--3
          u-margin-bottom--3
          sm:u-margin-top--6 sm:u-margin-bottom--6
        "
        data-anchor-text="Why you'll love this trip"
        v-bind="wyltt"
      />
      <Separator
        v-if="wyltt && wyltt.description && ittrfy && ittrfy.description"
        class="separator--narrow"
        data-cy="separator__middle"
      />
      <ITTRFY
        id="ITTRFY"
        class="
          u-margin-top--3
          u-margin-bottom--3
          sm:u-margin-top--6 sm:u-margin-bottom--6
        "
        data-anchor-text="Is this trip right for you?"
        v-bind="ittrfy"
      />
    </ErrorBoundary>
    <Separator
      v-if="(wyltt && wyltt.description) || (ittrfy && ittrfy.description)"
      data-cy="separator__bottom"
    />
    <ErrorBoundary
      v-if="boats && boats.boat?.length"
      id="boats"
      data-anchor-text="Boats"
      class="l-container"
    >
      <h2 data-cy="trip-page__boatlist-title">Boats</h2>
      <BoatList :boats="boats.boat" data-cy="trip-page__boatlist" />
    </ErrorBoundary>
    <ErrorBoundary
      v-if="itinerary && itinerary.length"
      id="itinerary"
      class="l-container u-margin-top--3 sm:u-top-bottom--6"
      data-anchor-text="Itinerary"
    >
      <div class="l-grid">
        <div
          class="
            l-grid__cell
            l-grid__cell--4-col-desktop
            l-grid__cell--hide-phone
            l-grid__cell--hide-tablet
          "
        ></div>
        <div
          class="l-grid__cell l-grid__cell--12-col l-grid__cell--8-col-desktop"
        >
          <h2>Itinerary</h2>
          <TripEffectiveAlert
            v-if="tripEffectiveAlert"
            v-bind="tripEffectiveAlert"
            class="u-margin-bottom--2"
          />
        </div>
      </div>
      <div class="l-grid">
        <div
          class="l-grid__cell l-grid__cell--12-col l-grid__cell--4-col-desktop"
        >
          <Imagery
            v-if="productMap"
            class="u-margin-top-5 trip-page__itinerary-map"
            data-cy="trip-page__map"
            :lazy-src="productMap.src"
            :lazy-srcset="productMap.srcSet"
            :background-color="productMap.backgroundColor"
            :alt="productMap.alt"
          />
        </div>
        <ErrorBoundary
          v-if="itinerary && itinerary.length"
          class="l-grid__cell l-grid__cell--12-col l-grid__cell--8-col-desktop"
        >
          <TripItinerary
            class="trip-page__itinerary"
            :show-title="false"
            :itinerary="itinerary"
          />
        </ErrorBoundary>
      </div>
      <TripRunsInReverse v-if="tripRunsInReverse" v-bind="tripRunsInReverse" />
    </ErrorBoundary>

    <ErrorBoundary
      v-if="isThereValuesInObject(inclusions)"
      id="inclusions"
      class="l-container u-margin-top--4 md:u-margin-top--6"
      data-anchor-text="Inclusions"
    >
      <h2>Inclusions</h2>
      <TripInclusions v-bind="inclusions"></TripInclusions>
    </ErrorBoundary>

    <ErrorBoundary
      v-if="departures"
      id="Dates-&-availabilities"
      class="
        l-container l-container--bleed-phone
        u-margin-top--4
        md:u-margin-top--6
        trip-page__departures
      "
      data-cy="trip-page__departures"
      data-anchor-text="Dates and availability"
    >
      <h2
        class="
          xs:u-padding-left--1 xs:u-padding-right--1
          sm:u-padding-left--0 sm:u-padding-right--0
        "
        data-cy="trip-page__departures-title"
      >
        Dates and availability
      </h2>

      <TripDepartures
        :themes="themes"
        :productSubType="productSubType"
        v-bind="departures"
      />
    </ErrorBoundary>

    <ErrorBoundary
      v-if="importantNotes || etiLink"
      class="u-background-color--sand u-margin-top--4 sm:u-margin-top--6"
    >
      <div
        v-if="importantNotes"
        id="important-notes"
        data-anchor-text="Important notes"
        class="l-container u-padding-top--5"
        data-cy="trip-page__important-notes"
      >
        <h2 class="u-margin-top--0 md:u-margin-bottom--1-5">Important notes</h2>
        <RichText :html="importantNotes" />
      </div>

      <div
        v-if="etiLink"
        id="essential-trip-information"
        data-anchor-text="Essential Trip Information"
        class="l-container u-padding-top--6 u-padding-bottom--6"
        data-cy="trip-page__eti-link"
      >
        <ETILink v-bind="etiLink" />
      </div>
    </ErrorBoundary>

    <ErrorBoundary class="l-container">
      <Reviews
        v-if="reviews?.reviewComments?.length"
        data-anchor-text="Reviews"
        v-bind="reviewAggregate"
        :reviews="reviews"
        class="u-margin-top--4 sm:u-margin-top--6"
      >
        <h2 slot="title">Reviews</h2>
      </Reviews>
      <TripNoReviews v-else-if="tripNoReviews" v-bind="tripNoReviews" />
    </ErrorBoundary>

    <TripCallToActionBar
      v-if="fromPriceDiscount && tripDisplayName"
      :fromPriceDiscount="fromPriceDiscount"
      :tripDisplayName="tripDisplayName"
    />
  </Page>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Imagery from "atlas/src/components/Imagery/Imagery.vue";
import Separator from "atlas/src/components/Separator/Separator.vue";
import WYLTT from "../WYLTT/WYLTT.vue";
import ITTRFY from "../ITTRFY/ITTRFY.vue";
import TripEffectiveAlert from "../TripEffectiveAlert/TripEffectiveAlert.vue";
import { TripPageProps } from "./Props";
import { TripPage } from "./TripPageSchema";
import Gallery from "~~/components/Gallery/Gallery.vue";
import Introduction from "~~/components/Introduction/Introduction.vue";
import TripSnapshot from "~~/components/TripSnapshot/TripSnapshot.vue";
import TripExcludedSalesRegionAlert from "~~/components/TripExcludedSalesRegionAlert/TripExcludedSalesRegionAlert.vue";
import Page from "~~/components/Page/Page.vue";
import TripSummary from "~/components/TripSummary/TripSummary.vue";
import Reviews from "~/components/Reviews/Reviews.vue";
import RichText from "~/components/RichText/RichText.vue";
import SubNavigation from "~/components/SubNavigation/SubNavigation.vue";
import DealPromotionCard from "~/components/DealPromotionCard/DealPromotionCard.vue";
import TripItinerary from "~~/components/TripItinerary/TripItinerary.vue";
import TripRunsInReverse from "~~/components/TripRunsInReverse/TripRunsInReverse.vue";
import TripDepartures from "~~/components/TripDepartures/TripDepartures.vue";
import TripInclusions from "~~/components/TripInclusions/TripInclusions.vue";
import ErrorBoundary from "~~/components/ErrorBoundary/ErrorBoundary.vue";
import TripCallToActionBar from "~~/components/TripCallToActionBar/TripCallToActionBar.vue";
import ETILink from "~~/components/ETILink/ETILink.vue";
import ThemeProductMessaging from "~/components/ThemeProductMessaging/ThemeProductMessaging.vue";
import TripNoReviews from "~~/components/TripNoReviews/TripNoReviews.vue";
import { isThereValuesInObject } from "~/lib/utils/isThereValuesInObject";
import { UniqueSellingPointProps } from "~~/components/UniqueSellingPoint/Props";
import UniqueSellingPointList from "~~/components/UniqueSellingPointList/UniqueSellingPointList.vue";
import CarbonCalculation from "~~/components/CarbonCalculation/CarbonCalculation.vue";
import BoatList from "~~/components/BoatList/BoatList.vue";
import TravelAlert from "~~/components/TravelAlert/TravelAlert.vue";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";
import { DealPromotionCardProps } from "~/components/DealPromotionCard/Props";

const logger = loggerFactory({
  tags: [logTags.Layer.Component, logTags.Page.Product],
});

export default Vue.extend({
  name: "TripPage",
  components: {
    Page,
    Introduction,
    WYLTT,
    ITTRFY,
    Separator,
    TripSnapshot,
    TripExcludedSalesRegionAlert,
    TripSummary,
    SubNavigation,
    DealPromotionCard,
    Reviews,
    Imagery,
    TripItinerary,
    RichText,
    TripInclusions,
    ETILink,
    TripDepartures,
    TripCallToActionBar,
    TripEffectiveAlert,
    ThemeProductMessaging,
    Gallery,
    ErrorBoundary,
    BoatList,
    TripNoReviews,
    UniqueSellingPointList,
    CarbonCalculation,
    TripRunsInReverse,
    TravelAlert,
  },
  props: {
    productCode: {
      type: String as PropType<TripPageProps["productCode"]>,
      required: true,
      default: undefined,
    },
    tripDisplayName: {
      type: String as PropType<TripPageProps["tripDisplayName"]>,
      required: true,
    },
    page: {
      type: Object as PropType<TripPageProps["page"]>,
      required: true,
    },
    tripEffectiveAlert: {
      type: Object as PropType<TripPageProps["tripEffectiveAlert"]>,
      required: false,
      default() {
        return undefined;
      },
    },
    introduction: {
      type: Object as PropType<TripPageProps["introduction"]>,
      required: false,
      default: undefined,
    },
    reviewAggregate: {
      type: Object as PropType<TripPageProps["reviewAggregate"]>,
      required: false,
      default: undefined,
    },
    reviews: {
      type: Object as PropType<TripPageProps["reviews"]>,
      required: false,
      default: undefined,
    },
    wyltt: {
      type: Object as PropType<TripPageProps["wyltt"]>,
      required: false,
      default: undefined,
    },
    ittrfy: {
      type: Object as PropType<TripPageProps["ittrfy"]>,
      required: false,
      default: undefined,
    },
    duration: {
      type: Number as PropType<TripPageProps["duration"]>,
      required: false,
      default: undefined,
    },
    tagline: {
      type: String as PropType<TripPageProps["tagline"]>,
      required: false,
      default: undefined,
    },
    themes: {
      type: Array as PropType<TripPageProps["themes"]>,
      required: false,
      default: undefined,
    },
    fromPriceDiscount: {
      type: Object as PropType<TripPageProps["fromPriceDiscount"]>,
      required: false,
      default: undefined,
    },
    tripSummary: {
      type: Object as PropType<TripPageProps["tripSummary"]>,
      required: true,
    },
    productMap: {
      required: false,
      type: Object as PropType<TripPageProps["productMap"]>,
      default: undefined,
    },
    itinerary: {
      type: Array as PropType<TripPageProps["itinerary"]>,
      required: false,
      default() {
        return undefined;
      },
    },
    importantNotes: {
      type: String as PropType<TripPageProps["importantNotes"]>,
      required: false,
      default: undefined,
    },
    inclusions: {
      type: Object as PropType<TripPageProps["inclusions"]>,
      required: false,
      default: undefined,
    },
    etiLink: {
      type: Object as PropType<TripPageProps["etiLink"]>,
      required: false,
      default: undefined,
    },
    departures: {
      type: Object as PropType<TripPageProps["departures"]>,
      required: false,
      default: undefined,
    },
    themeProductMessaging: {
      type: Array as PropType<TripPageProps["themeProductMessaging"]>,
      required: false,
      default: undefined,
    },
    gallery: {
      required: false,
      type: Object as PropType<TripPageProps["gallery"]>,
      default: undefined,
    },
    boats: {
      required: false,
      type: Object as PropType<TripPageProps["boats"]>,
      default: undefined,
    },
    tripNoReviews: {
      type: Object as PropType<TripPageProps["tripNoReviews"]>,
      required: false,
      default: undefined,
    },
    uniqueSellingPoints: {
      type: Array as PropType<UniqueSellingPointProps[]>,
      required: false,
      default: undefined,
    },
    carbonCalculation: {
      type: Object as PropType<TripPageProps["carbonCalculation"]>,
      required: false,
      default: undefined,
    },
    tripRunsInReverse: {
      type: Object as PropType<TripPageProps["tripRunsInReverse"]>,
      required: false,
      default: undefined,
    },
    excludedSaleRegions: {
      type: Array as PropType<TripPageProps["excludedSaleRegions"]>,
      required: false,
      default: undefined,
    },
    productSubType: {
      type: Number as PropType<TripPageProps["productSubType"]>,
      required: true,
    },
    travelAlert: {
      type: Object as PropType<TripPageProps["travelAlert"]>,
      required: false,
      default: undefined,
    },
  },
  data(): {
    dealPromotion: DealPromotionCardProps | undefined;
  } {
    return {
      dealPromotion: undefined,
    };
  },
  computed: {
    pageType(): string {
      return this.$store.getters["page/getPageType"];
    },
    showCarbonCalculation(): boolean {
      return this.$config?.public?.showCarbonCalculationOnTripPage;
    },
    isExcludedSalesRegion() {
      if (!this.excludedSaleRegions || this.excludedSaleRegions.length === 0) {
        return false;
      }
      return this.excludedSaleRegions?.includes(this.$i18n.locale);
    },
    computedAlertSpacingClass(): string {
      return this.travelAlert
        ? "l-container u-margin-top--1"
        : "l-container sm:u-margin-top--1 u-margin-top--0-5";
    },
  },
  async created() {
    this.getAggregateRating();

    if (process.client) {
      try {
        const countriesVisited = this.tripSummary?.destinations.map(
          (item) => item.href
        );
        this.dealPromotion = await $fetch<DealPromotionCardProps | undefined>(
          "/api/nuxt/deal/promotion",
          {
            params: {
              localeCode: this.$i18n.locale,
              localeIso: this.$i18n.localeProperties.iso,
              destinationSlugs: JSON.stringify(countriesVisited),
              productCode: this.productCode,
            },
          }
        );
      } catch (error) {
        logger.error("Fetching deal promotion failed on Trip page", error);
      }
    }
  },
  methods: {
    getAggregateRating() {
      const jsonLdData: TripPage[] = this.page?.jsonld;
      jsonLdData?.map((jsonLd) => {
        if (
          jsonLd["@type"] === "Product" &&
          this.reviewAggregate?.averageRating &&
          this.reviewAggregate?.reviewsCount
        ) {
          jsonLd.aggregateRating = {
            "@type": "AggregateRating",
            ratingValue: this.reviewAggregate?.averageRating,
            reviewCount: this.reviewAggregate?.reviewsCount,
          };
        }
        return jsonLd;
      });
    },
    isThereValuesInObject,
  },
});
</script>
<style lang="scss">
@import "./trip-page";
</style>
