<template>
  <AppLink class="button button--primary button--block" :to="searchLinkUrl">
    <Icon name="magnify" class="search-link__icon" /> &nbsp; Search similar
    trips
  </AppLink>
</template>

<script lang="ts">
import Vue from "vue";
import AppLink from "atlas/src/components/AppLink/AppLink.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import "atlas/src/components/Icon/icons/magnify.js";

export default Vue.extend({
  name: "TripList",
  components: {
    AppLink,
    Icon,
  },
  props: {
    destinationDisplayName: {
      type: String,
      required: true,
    },
  },
  computed: {
    searchLinkUrl(): string {
      if (!this.destinationDisplayName) {
        return "";
      }

      return this.$link.create(
        `/search?country=${this.destinationDisplayName.toLowerCase()}`
      );
    },
  },
});
</script>

<style lang="scss">
@import "./search-link";
</style>
