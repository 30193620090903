<template>
  <ErrorBoundary>
    <Video v-if="video" :url="video.url" :ssr="true" />
  </ErrorBoundary>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Video from "atlas/src/components/Video/Video.vue";
import { VideoPanelProps } from "./Props";
import ErrorBoundary from "~/components/ErrorBoundary/ErrorBoundary.vue";
export default Vue.extend({
  name: "VideoPanel",
  components: {
    Video,
    ErrorBoundary,
  },
  props: {
    video: {
      type: Object as PropType<VideoPanelProps["video"]>,
      required: true,
    },
  },
});
</script>
