<template>
  <UniqueSellingPointList
    v-if="uniqueSellingPoints.length"
    :unique-selling-points="uniqueSellingPoints"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import UniqueSellingPointList from "../UniqueSellingPointList/UniqueSellingPointList.vue";
import { UniqueSellingPointProps } from "../UniqueSellingPoint/Props";

export default Vue.extend({
  name: "UniqueSellingPointPanel",
  components: {
    UniqueSellingPointList,
  },
  props: {
    uniqueSellingPoints: {
      type: Array as PropType<UniqueSellingPointProps[]>,
      required: true,
    },
  },
});
</script>
