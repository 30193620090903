<template>
  <div v-if="links && links.length" class="tile-links">
    <h2>{{ title }}</h2>
    <div class="l-grid">
      <div
        v-for="(item, key) in links"
        :key="key"
        class="
          l-grid__cell
          l-grid__cell--12-col
          l-grid__cell--4-col-tablet
          l-grid__cell--3-col-desktop
        "
      >
        <TileLink :title="item.title" :link="item.link" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { TileLinksProps } from "./Props";
import TileLink from "~/components/TileLink/TileLink.vue";

export default Vue.extend({
  name: "TileLinks",
  components: { TileLink },
  props: {
    title: {
      type: String as PropType<TileLinksProps["title"]>,
      required: true,
    },
    links: {
      type: Array as PropType<TileLinksProps["links"]>,
      required: true,
    },
  },
});
</script>

<style lang="scss">
@import "./tile-links";
</style>
