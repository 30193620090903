<template>
  <div
    v-if="(trips && trips.length > 0) || tailorMadeTripCard"
    data-cy="trip-list"
  >
    <div v-if="destinationDisplayName" class="l-grid">
      <div
        class="
          l-grid__cell
          l-grid__cell--12-col
          l-grid__cell--12-col-tablet
          l-grid__cell--8-col-desktop
        "
      >
        <h2
          v-if="title"
          data-cy="trip-list_title"
          class="u-padding-top--1 u-margin-bottom--0"
        >
          {{ title }}
        </h2>
      </div>
      <div
        v-if="showSearchButton"
        class="
          l-grid__cell
          l-grid__cell--12-col
          l-grid__cell--12-col-tablet
          l-grid__cell--4-col-desktop
          u-display--flex
          u-flex-direction--column
          u-justify-content--flex-end
        "
      >
        <SearchLink
          data-cy="trip-list_search-link"
          :destinationDisplayName="destinationDisplayName"
        />
      </div>
    </div>
    <JsonRichText
      v-if="description"
      data-cy="trip-list_description"
      v-bind="description"
    />

    <div class="l-grid u-margin-top--1 md:u-margin-top--0">
      <div
        v-for="(trip, index) of tripsBeforeTailorMade"
        :key="index"
        class="
          l-grid__cell
          l-grid__cell--flex
          l-grid__cell--4-col-tablet
          l-grid__cell--4-col-desktop
        "
      >
        <TripCard :type="type" v-bind="trip" />
      </div>
      <div
        v-if="tailorMadeTripCard"
        class="
          l-grid__cell
          l-grid__cell--flex
          l-grid__cell--4-col-tablet
          l-grid__cell--4-col-desktop
        "
      >
        <TailorMadeCard v-bind="tailorMadeTripCard"></TailorMadeCard>
      </div>
      <template v-for="(trip, index) of tripsAfterTailorMade">
        <div
          v-if="index + indexToInsertTailorMade < currentDisplayIndex"
          :key="'afterTailorMade-' + index"
          class="
            l-grid__cell
            l-grid__cell--flex
            l-grid__cell--4-col-tablet
            l-grid__cell--4-col-desktop
          "
        >
          <TripCard :type="getCardType(index)" v-bind="trip" />
        </div>
      </template>
    </div>

    <div v-if="displayShowMoreButton" class="l-grid">
      <div
        class="
          l-grid__cell
          l-grid__cell--12-col
          l-grid__cell--2-col-tablet
          l-grid__cell--3-col-desktop
        "
      ></div>
      <div
        class="
          l-grid__cell
          l-grid__cell--12-col
          l-grid__cell--4-col-tablet
          l-grid__cell--6-col-desktop
        "
      >
        <Button
          class="button--secondary button--block"
          data-cy="view-more-trips"
          @click.native="viewMoreTrips"
          >View more trips</Button
        >
      </div>
      <div
        class="
          l-grid__cell
          l-grid__cell--12-col
          l-grid__cell--2-col-tablet
          l-grid__cell--3-col-desktop
        "
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import { TripListProps } from "./Props";
import TripCard from "~/components/TripCard/TripCard.vue";
import SearchLink from "~/components/SearchLink/SearchLink.vue";
import TailorMadeCard from "~/components/TailorMadeCard/TailorMadeCard.vue";
import JsonRichText from "~/components/JsonRichText/JsonRichText.vue";

const TRIP_CARDS_PER_BATCH = 6;

export default Vue.extend({
  name: "TripList",
  components: {
    JsonRichText,
    TripCard,
    Button,
    SearchLink,
    TailorMadeCard,
  },
  props: {
    type: {
      type: String,
      default: "default",
      required: false,
    },
    title: {
      type: String as PropType<TripListProps["title"]>,
      required: false,
      default: undefined,
    },
    description: {
      type: Object as PropType<TripListProps["description"]>,
      required: false,
      default: undefined,
    },
    destinationDisplayName: {
      type: String as PropType<TripListProps["destinationDisplayName"]>,
      required: false,
      default: undefined,
    },
    trips: {
      type: Array as PropType<TripListProps["trips"]>,
      default() {
        return [];
      },
      required: false,
    },
    tailorMadeTripCard: {
      type: Object as PropType<TripListProps["tailorMadeTripCard"]>,
      required: false,
      default: undefined,
    },
    showSearchButton: {
      type: Boolean as PropType<TripListProps["showSearchButton"]>,
      required: false,
      default: undefined,
    },
  },
  data(): { currentDisplayIndex: number; indexToInsertTailorMade: number } {
    return {
      currentDisplayIndex: TRIP_CARDS_PER_BATCH - 1,
      indexToInsertTailorMade: !this.$props.tailorMadeTripCard
        ? -1
        : TRIP_CARDS_PER_BATCH - 1,
    };
  },
  computed: {
    tripsAfterTailorMade(): TripListProps["trips"] {
      return this.trips.filter(
        (_, index) => index >= this.indexToInsertTailorMade
      );
    },
    tripsBeforeTailorMade(): TripListProps["trips"] {
      return this.trips.filter(
        (_, index) => index < this.indexToInsertTailorMade
      );
    },
    displayShowMoreButton(): boolean {
      if (!this.$props.tailorMadeTripCard) {
        return this.currentDisplayIndex < this.trips.length - 1;
      }
      return this.currentDisplayIndex < this.trips.length;
    },
  },
  methods: {
    viewMoreTrips() {
      if (this.currentDisplayIndex < this.trips.length) {
        this.currentDisplayIndex += TRIP_CARDS_PER_BATCH;
      }
    },
    getCardType(index: number) {
      return index + this.indexToInsertTailorMade < this.currentDisplayIndex
        ? this.type
        : "mini";
    },
  },
});
</script>
