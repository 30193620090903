<template>
  <div class="l-container">
    <Alert type="warning" data-cy="sales-region-alert" class="u-margin-top--1">
      <div slot="content">
        {{
          "Please note that this offer may not be available in your region, please view the full terms & conditions for a list of valid countries."
        }}
      </div>
    </Alert>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Alert from "atlas/src/components/Alert/Alert.vue";

export default Vue.extend({
  name: "SalesRegionAlert",
  components: {
    Alert,
  },
});
</script>
