<template>
  <div v-if="url" class="trip-runs-in-reverse" data-cy="trip-runs-in-reverse">
    <Button
      :href="$link.create(url)"
      class="button--primary u-margin-top--1-5 trip-runs-in-reverse__view-trip"
    >
      <Icon name="sync" class="icon--size-1-5 u-margin-right--0-5" />
      View this trip in reverse
    </Button>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Button from "atlas/src/components/Button/Button.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import "atlas/src/components/Icon/icons/sync";
import { TripRunsInReverseProps } from "./Props";

export default Vue.extend({
  name: "TripRunsInReverse",
  components: { Button, Icon },
  props: {
    url: {
      type: String as PropType<TripRunsInReverseProps["url"]>,
      required: true,
    },
  },
});
</script>

<style lang="scss">
@import "./trip-runs-in-reverse";
</style>
