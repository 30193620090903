<template>
  <TripList
    :trips="trips"
    :tailorMadeTripCard="tailorMadeTripCard"
    :showSearchButton="showSearchButton"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import TripList from "../TripList/TripList.vue";
import { TripListProps } from "../TripList/Props";

export default Vue.extend({
  name: "TripPanel",
  components: { TripList },

  props: {
    trips: {
      type: Array as PropType<TripListProps["trips"]>,
      required: false,
      default: () => [],
    },
    tailorMadeTripCard: {
      type: Object as PropType<TripListProps["tailorMadeTripCard"]>,
      required: false,
      default: undefined,
    },
    showSearchButton: {
      type: Boolean as PropType<TripListProps["showSearchButton"]>,
      required: true,
    },
  },
});
</script>
