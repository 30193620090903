<template>
  <AccordionItem :expanded="expanded">
    <b slot="title">{{ getDayTitle }}</b>
    <div slot="content">
      <RichText
        v-if="tripItineraryDay.description"
        :html="tripItineraryDay.description"
      />
      <div class="trip-itinerary-day__meta-data-section u-margin-top--2">
        <div class="l-grid">
          <div
            class="l-grid__cell l-grid__cell--6-col l-grid__cell--12-col-tablet"
          >
            <div class="l-grid">
              <div
                v-if="getAccommodation.length"
                class="l-grid__cell l-grid__cell--12-col"
              >
                <div class="u-font-weight--bold">
                  <Icon name="hotel" class="icon--inline" role="presentation" />
                  Accommodation
                </div>
                <ul class="u-padding-left--2">
                  <li>{{ getAccommodation }}</li>
                </ul>
              </div>
              <div class="l-grid__cell l-grid__cell--12-col">
                <div class="u-font-weight--bold">
                  <Icon
                    name="fork-knife"
                    class="icon--inline"
                    role="presentation"
                  />
                  Meals
                </div>
                <ul v-if="getMeals.length" class="u-padding-left--2">
                  <li
                    v-for="(item, index) in getMeals"
                    :key="index"
                    class="trip-itinerary-day__meals-list-item"
                  >
                    {{ item }}
                  </li>
                </ul>
                <p v-else class="u-margin-top--1">
                  There are no meals included on this day.
                </p>
              </div>
            </div>
          </div>
          <div
            class="l-grid__cell l-grid__cell--6-col l-grid__cell--12-col-tablet"
          >
            <div class="l-grid">
              <div
                v-if="hasIncludedActivities"
                class="l-grid__cell l-grid__cell--12-col"
              >
                <div class="u-font-weight--bold">
                  <Icon
                    name="experiences"
                    class="icon--inline"
                    role="presentation"
                  />
                  Included activities
                </div>
                <ul class="u-padding-left--2">
                  <li
                    v-for="(item, index) in tripItineraryDay.includedActivities"
                    :key="index"
                    data-cy="included-activities-list-item"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
              <div
                v-if="hasOptionalActivities"
                class="l-grid__cell l-grid__cell--12-col"
              >
                <div class="u-font-weight--bold">
                  <Icon name="plus" class="icon--inline" role="presentation" />
                  Add on activities
                </div>
                <ul class="u-padding-left--2">
                  <li
                    v-for="(item, index) in tripItineraryDay.optionalActivities"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="trip-itinerary-day__special-information-wrapper u-margin-top--1"
      >
        <div v-if="tripItineraryDay.specialInformation">
          <div class="u-font-weight--bold u-margin-bottom--1">
            Special information
          </div>
          <RichText :html="tripItineraryDay.specialInformation" />
        </div>
      </div>
    </div>
  </AccordionItem>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import AccordionItem from "atlas/src/components/Accordion/AccordionItem.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import RichText from "../RichText/RichText.vue";
import { TripItineraryDayProps } from "./Props";

export default Vue.extend({
  name: "TripItineraryDay",
  components: { AccordionItem, RichText, Icon },
  props: {
    tripItineraryDay: {
      type: Object as PropType<TripItineraryDayProps>,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    hasIncludedActivities(): boolean {
      const { includedActivities } = this.tripItineraryDay;
      return !!(includedActivities && includedActivities.length) as boolean;
    },
    hasOptionalActivities(): boolean {
      const { optionalActivities } = this.tripItineraryDay;
      return !!(optionalActivities && optionalActivities.length);
    },
    getMeals(): string[] {
      const { mealDetails } = this.tripItineraryDay;

      return (
        mealDetails &&
        mealDetails.map((mealDetail) => {
          const { mealName, noOfMeals } = mealDetail;
          const sanitizedMealName =
            mealName.charAt(0).toUpperCase() + mealName.slice(1);
          return noOfMeals !== 1
            ? `${sanitizedMealName} (${noOfMeals})`
            : `${sanitizedMealName}`;
        })
      );
    },
    getAccommodation(): string {
      const { accommodationName, accommodationNights } = this.tripItineraryDay;

      return `${accommodationName ? `${accommodationName} ` : ``}${
        accommodationNights
          ? `(${this.$tc("night", accommodationNights, {
              n: accommodationNights,
            })})`
          : ``
      }`;
    },
    getDayTitle(): string {
      const { firstDay, lastDay, dayTitle } = this.tripItineraryDay;
      return `Day ${
        Math.abs(firstDay - lastDay) > 0
          ? `${firstDay}-${lastDay}`
          : `${firstDay}`
      }: ${dayTitle}`;
    },
  },
});
</script>
