<template>
  <Alert v-if="isVisible" type="informational" data-cy="trip-effective-alert">
    <div slot="content">
      <span class="u-font-weight--bold">
        This itinerary is valid for departures from
        {{ formatDate(effectiveFrom) }} to {{ formatDate(effectiveTo) }}.
      </span>

      <template v-if="seasons && seasons.length > 1">
        <ul class="u-margin-bottom--0 u-margin-top--0">
          <li v-for="(season, index) of seasons" :key="index">
            <Link class="" :href="formatUrl(season.url)"
              >View the itinerary for departures between
              {{ formatDate(season.effectiveFrom) }} -
              {{ formatDate(season.effectiveTo) }}</Link
            >
          </li>
        </ul>
      </template>
      <template v-else-if="seasons && seasons.length == 1">
        <p class="u-margin-bottom--0">
          <Link class="" :href="formatUrl(seasons[0].url)"
            >View the itinerary for departures between
            {{ formatDate(seasons[0].effectiveFrom) }} -
            {{ formatDate(seasons[0].effectiveTo) }}</Link
          >
        </p>
      </template>
    </div>
  </Alert>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Alert from "atlas/src/components/Alert/Alert.vue";
import dayjs, { extend } from "dayjs";
import utc from "dayjs/plugin/utc.js";
import Link from "../Link/Link.vue";
import { TripEffectiveAlertProps, TripSeason } from "./Props";

extend(utc);

export default Vue.extend({
  name: "TripEffectiveAlert",
  components: {
    Alert,
    Link,
  },
  props: {
    effectiveFrom: {
      type: String as PropType<TripEffectiveAlertProps["effectiveFrom"]>,
      required: true,
      default: null,
    },
    effectiveTo: {
      type: String as PropType<TripEffectiveAlertProps["effectiveTo"]>,
      required: true,
      default: null,
    },
    nextSeason: {
      type: Array as PropType<TripEffectiveAlertProps["nextSeason"]>,
      required: false,
      default: null,
    },
    previousSeason: {
      type: Array as PropType<TripEffectiveAlertProps["previousSeason"]>,
      required: false,
      default: null,
    },
    isEti: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    seasons(): TripSeason[] {
      return [
        ...(this.previousSeason || []),
        ...(this.nextSeason || []),
      ].filter((season) => this.isEffectiveNow(season.effectiveTo));
    },
    isVisible(): boolean {
      return (
        !!this.effectiveFrom &&
        !!this.effectiveTo &&
        this.seasons?.length > 0 &&
        this.isEffectiveNow(this.effectiveTo)
      );
    },
  },
  methods: {
    formatDate(date: dayjs.ConfigType) {
      return dayjs.utc(date).format("DD MMMM YYYY");
    },
    isEffectiveNow(effectiveTo?: string) {
      if (!effectiveTo) {
        return false;
      }
      return dayjs.utc().isBefore(dayjs.utc(effectiveTo).toDate());
    },
    formatUrl(url: string) {
      return this.isEti ? url + "/tripnotes" : url;
    },
  },
});
</script>
