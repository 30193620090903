<template>
  <Departures
    :availableYearsAndMonths="availableYearsAndMonths"
    :departures="departures"
    :currentPage="currentPage"
    :numberOfPages="numberOfPages"
    :isLoading="isLoading"
    :mainCountry="mainCountry"
    :themes="themes"
    :productSubType="productSubType"
    data-cy="trip-departures"
    @fetchNextPage="fetchNextPage"
    @fetchDeparturesByDate="fetchDeparturesByDate"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { DeparturesRoomDetailsModalProps } from "../Departures/DeparturesRoomDetails/Props";
import { TripDeparturesProps } from "./Props";
import Departures from "~~/components/Departures/Departures.vue";
import { isEmpty } from "~/lib/utils/isEmpty";
import { getDateAsAvailabilitiesStartDateString } from "~/lib/utils/getDateAsAvailabilitiesStartDateString";
import { loggerFactory, logTags } from "~~/lib/utils/logger/logger";

const logger = loggerFactory({
  tags: [logTags.Layer.Component],
});

type DeparturesAndAvailabilitiesParams = {
  product_code: string;
  product_id: number;
  currency_code: string;
  start_date?: string;
  page_number?: number;
  previous_season_product_id: number;
  previous_season_product_url: string;
  next_season_product_id: number;
  next_season_product_url: string;
};

type DeparturesAndAvailabilitiesData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableYearsAndMonths: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  departures: Record<string, any>;
  numberOfPages: number;
  currentPage: number;
};

export default Vue.extend({
  name: "TripDepartures",
  components: {
    Departures,
  },
  props: {
    productCode: {
      type: String as PropType<TripDeparturesProps["productCode"]>,
      required: true,
    },
    productId: {
      type: Number as PropType<TripDeparturesProps["productId"]>,
      required: true,
    },
    previousSeasonProductId: {
      type: Number as PropType<TripDeparturesProps["previousSeasonProductId"]>,
      required: false,
      default: undefined,
    },
    previousSeasonProductUrl: {
      type: String as PropType<TripDeparturesProps["previousSeasonProductUrl"]>,
      required: false,
      default: undefined,
    },
    nextSeasonProductId: {
      type: Number as PropType<TripDeparturesProps["nextSeasonProductId"]>,
      required: false,
      default: undefined,
    },
    nextSeasonProductUrl: {
      type: String as PropType<TripDeparturesProps["nextSeasonProductUrl"]>,
      required: false,
      default: undefined,
    },
    mainCountry: {
      type: Object as PropType<TripDeparturesProps["mainCountry"]>,
      required: false,
      default: undefined,
    },
    themes: {
      type: Array as PropType<DeparturesRoomDetailsModalProps["themes"]>,
      required: false,
      default: () => [],
    },
    productSubType: {
      type: Number as PropType<
        DeparturesRoomDetailsModalProps["productSubType"]
      >,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      availableYearsAndMonths: {},
      departures: {},
      numberOfPages: 0,
      currentPage: 0,
      selectedDate: "",
      isLoading: true,
    };
  },
  computed: {
    hasAvailabilities() {
      return !isEmpty(this.availableYearsAndMonths);
    },
    hasDepartures() {
      return !isEmpty(this.departures);
    },
  },
  async created() {
    if (process.client) {
      this.selectedDate = getDateAsAvailabilitiesStartDateString(new Date());
      const params = {
        product_code: this.productCode,
        product_id: this.productId,
        start_date: this.selectedDate,
        page_number: 1,
        currency_code:
          this.$route.params.currencyCode ??
          this.$i18n.localeProperties.currencyCode,
        previous_season_product_id: this.previousSeasonProductId,
        previous_season_product_url: this.previousSeasonProductUrl,
        next_season_product_id: this.nextSeasonProductId,
        next_season_product_url: this.nextSeasonProductUrl,
      };
      const departuresAndAvailabilitiesData: DeparturesAndAvailabilitiesData =
        await this.fetchDeparturesAndAvailabilities(
          params as DeparturesAndAvailabilitiesParams
        );

      this.updateDeparturesAndAvailabilitiesData(
        departuresAndAvailabilitiesData
      );
    }
  },
  methods: {
    async fetchDeparturesAndAvailabilities(
      departuresAndAvailabilitiesParams: DeparturesAndAvailabilitiesParams
    ): Promise<DeparturesAndAvailabilitiesData> {
      try {
        this.isLoading = true;

        const departuresAndAvailabilitiesData: DeparturesAndAvailabilitiesData =
          await $fetch(
            "/api/nuxt/product/available-years-months-and-departures",
            {
              params: departuresAndAvailabilitiesParams,
            }
          );

        this.isLoading = false;

        return departuresAndAvailabilitiesData;
      } catch (error) {
        logger.error("Fetching Departures failed", {
          functionName: "fetchDeparturesAndAvailabilities",
          error,
        });
        this.isLoading = false;
        return {
          availableYearsAndMonths: this.availableYearsAndMonths,
          numberOfPages: 0,
          currentPage: 0,
          departures: {},
        };
      }
    },
    async fetchNextPage() {
      const params = {
        product_code: this.productCode,
        product_id: this.productId,
        start_date: this.selectedDate,
        page_number: Number(this.currentPage + 1),
        currency_code:
          this.$route.params.currencyCode ??
          this.$i18n.localeProperties.currencyCode,
        previous_season_product_id: this.previousSeasonProductId,
        previous_season_product_url: this.previousSeasonProductUrl,
        next_season_product_id: this.nextSeasonProductId,
        next_season_product_url: this.nextSeasonProductUrl,
      };

      const departuresAndAvailabilitiesData: DeparturesAndAvailabilitiesData =
        await this.fetchDeparturesAndAvailabilities(
          params as DeparturesAndAvailabilitiesParams
        );

      this.updateDeparturesAndAvailabilitiesData(
        departuresAndAvailabilitiesData,
        true
      );
    },
    async fetchDeparturesByDate(selectedDate: string) {
      this.selectedDate = `${selectedDate}-01`;
      this.departures = {};

      const params = {
        product_code: this.productCode,
        product_id: this.productId,
        start_date: this.selectedDate,
        page_number: 1,
        currency_code:
          this.$route.params.currencyCode ??
          this.$i18n.localeProperties.currencyCode,
        previous_season_product_id: this.previousSeasonProductId,
        previous_season_product_url: this.previousSeasonProductUrl,
        next_season_product_id: this.nextSeasonProductId,
        next_season_product_url: this.nextSeasonProductUrl,
      };

      const departuresAndAvailabilitiesData: DeparturesAndAvailabilitiesData =
        await this.fetchDeparturesAndAvailabilities(
          params as DeparturesAndAvailabilitiesParams
        );

      this.updateDeparturesAndAvailabilitiesData(
        departuresAndAvailabilitiesData
      );
    },
    updateDeparturesAndAvailabilitiesData(
      departuresAndAvailabilitiesData: DeparturesAndAvailabilitiesData,
      shouldMergeDepartures = false
    ) {
      if (
        JSON.stringify(
          departuresAndAvailabilitiesData.availableYearsAndMonths
        ) !== JSON.stringify(this.availableYearsAndMonths)
      ) {
        this.availableYearsAndMonths =
          departuresAndAvailabilitiesData.availableYearsAndMonths;
      }
      this.numberOfPages = departuresAndAvailabilitiesData.numberOfPages;
      this.currentPage = departuresAndAvailabilitiesData.currentPage;
      shouldMergeDepartures
        ? (this.departures = {
            ...this.departures,
            ...departuresAndAvailabilitiesData.departures,
          })
        : (this.departures = departuresAndAvailabilitiesData.departures);
    },
  },
});
</script>
