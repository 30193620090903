<template>
  <div
    data-cy="section-break-panel"
    class="u-margin-top--4 sm:u-margin-top--6"
  ></div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "SectionBreakPanel",
});
</script>
