<template>
  <Tile
    v-if="description"
    class="tile--x-large"
    data-cy="theme-product-messaging"
    :title="description"
    :icon="iconName"
  />
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import { ThemeProductMessagingProps } from "./Props";

export default Vue.extend({
  name: "ThemeProductMessaging",
  components: {
    Tile,
  },
  props: {
    iconName: {
      type: String as PropType<ThemeProductMessagingProps["iconName"]>,
      required: true,
    },
    description: {
      type: String as PropType<ThemeProductMessagingProps["description"]>,
      required: true,
    },
  },
});
</script>
