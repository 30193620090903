<template>
  <div
    class="l-fixed-action-panel u-padding-bottom--0"
    data-cy="trip-call-to-action-bar"
  >
    <div
      class="l-fixed-action-panel__panel u-padding-left--0 u-padding-right--0"
    >
      <div class="l-container md:u-padding-right--1-5 md:u-padding-left--1-5">
        <div class="l-grid">
          <div
            :class="
              'u-margin-top--auto u-margin-bottom--auto u-font-weight--bold l-grid__cell--hide-phone l-grid__cell--hide-tablet l-grid__cell ' +
              (fromPriceDiscount && fromPriceDiscount.value
                ? 'l-grid__cell--3-col'
                : 'l-grid__cell--6-col')
            "
          >
            <div
              class="trip-call-to-action-bar__display-name"
              data-cy="trip-call-to-action-bar__display-name"
            >
              {{ tripDisplayName }}
            </div>
          </div>
          <div
            v-if="fromPriceDiscount && fromPriceDiscount.value"
            class="
              l-grid__cell l-grid__cell--3-col
              trip-call-to-action-bar__price
              u-margin-top--auto
              u-margin-bottom--auto
              l-grid__cell--hide-phone l-grid__cell--hide-tablet
            "
            data-cy="trip-call-to-action-bar__price"
          >
            From
            <Price
              :value="fromPriceDiscount.value"
              :currency-code="fromPriceDiscount.currencyCode"
              :currency-locale="getLocaleByCode($i18n.locale).currencyLocale"
              :show-currency-code="true"
              :show-decimal-places="false"
              class="headline--5"
            />
          </div>
          <div
            class="
              l-grid__cell l-grid__cell--4-col
              trip-call-to-action-bar__button-wrapper
              l-grid__cell--3-col-phone l-grid__cell--4-col-tablet
            "
            data-cy="trip-call-to-action-bar__button-wrapper"
          >
            <Button
              class="button--primary"
              href="#Dates-&-availabilities"
              data-cy="trip-call-to-action-bar__button"
              ><Icon
                class="icon--size-1-5 u-margin-right--0-5"
                name="calendar"
              />View dates&nbsp;
              <span class="u-display--none sm:u-display--inline">
                and book</span
              ></Button
            >
          </div>
          <div
            class="
              l-grid__cell l-grid__cell--2-col
              trip-call-to-action-bar__live-chat-placeholder
              l-grid__cell--1-col-phone l-grid__cell--4-col-tablet
            "
          ></div>
        </div>
      </div>

      <client-only>
        <Teleport to="body">
          <div class="trip-call-to-action-bar__spacer"></div>
        </Teleport>
      </client-only>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Teleport from "vue2-teleport";
import Button from "atlas/src/components/Button/Button.vue";
import Price from "atlas/src/components/Price/Price.vue";
import Icon from "atlas/src/components/Icon/Icon.vue";
import "atlas/src/components/Icon/icons/calendar.js";
import { TripCallToActionBarProps } from "./Props";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";

export default Vue.extend({
  name: "TripCallToActionBar",
  components: { Button, Price, Icon, Teleport },
  props: {
    fromPriceDiscount: {
      type: Object as PropType<TripCallToActionBarProps["fromPriceDiscount"]>,
      required: true,
    },
    tripDisplayName: {
      type: String as PropType<TripCallToActionBarProps["tripDisplayName"]>,
      required: true,
    },
  },
  mounted() {
    this.addClassToLiveChatButtonWhenAddedToDOM();
  },
  destroyed() {
    this.removeClassFromLiveChatButton();
  },
  methods: {
    getLocaleByCode,
    addClassToLiveChatButton() {
      const liveChatButton = document.querySelector(".live-agent .helpButton");
      if (liveChatButton) {
        if (
          !liveChatButton.classList.contains(
            "live-chat-button--align-with-trip-cta"
          )
        ) {
          liveChatButton.classList.add("live-chat-button--align-with-trip-cta");

          return true;
        }
      }
      return false;
    },
    addClassToLiveChatButtonWhenAddedToDOM() {
      if (process.client) {
        const observer = new MutationObserver((mutationList, observer) => {
          for (const mutation of mutationList) {
            if (mutation.type === "childList") {
              if (this.addClassToLiveChatButton()) {
                observer.disconnect();
              }
            }
          }
        });
        observer.observe(document.querySelector("body")!, {
          childList: true,
          subtree: true,
        });
      }
    },
    removeClassFromLiveChatButton() {
      if (process.client) {
        const liveChatButton = document.querySelector(
          ".live-agent .helpButton.live-chat-button--align-with-trip-cta"
        );
        if (liveChatButton) {
          liveChatButton.classList.remove(
            "live-chat-button--align-with-trip-cta"
          );
        }
      }
    },
  },
});
</script>

<style lang="scss">
@import "./trip-call-to-action-bar";
</style>
