<template>
  <div
    v-if="similarReviewsUrl"
    class="u-padding-top--6"
    data-cy="trip-no-reviews"
  >
    <h2 class="u-margin-top--0">Reviews</h2>
    <Tile :title="title" :icon="icon" class="tile tile--x-large">
      <p class="u-margin-bottom--0">
        Check back later or
        <Link :href="similarReviewsUrl">
          explore reviews for similar trips.
        </Link>
      </p>
    </Tile>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Tile from "atlas/src/components/Tile/Tile.vue";
import Link from "../Link/Link.vue";

export default Vue.extend({
  name: "TripNoReviews",
  components: {
    Tile,
    Link,
  },
  props: {
    destinationUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: "Looks like this trip is a best-kept secret... for now!",
      icon: "reviews",
    };
  },
  computed: {
    similarReviewsUrl(): string | undefined {
      if (typeof this.destinationUrl !== "string") {
        return undefined;
      }
      return `${this.destinationUrl}#reviews`;
    },
  },
});
</script>
