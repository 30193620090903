<template>
  <Card
    :image="image"
    :label="label"
    class="tailor-made-card"
    data-cy="tailor-made-card"
    :class="{
      'card--horizontal': shouldRenderAsHorizontalCard,
    }"
  >
    <template slot="heading">
      <span class="tailor-made-card__heading">{{ description }}</span>
    </template>
    <template slot="content">
      <div
        class="
          u-display--flex
          u-flex-direction--column
          u-justify-content--space-between
          tailor-made-card__content-wrapper
        "
      >
        <p>Let's create an exclusive trip for your group.</p>
        <Button
          :href="$link.create(url)"
          class="button--secondary button--block"
        >
          Explore Tailor-Made trips
        </Button>
      </div>
    </template>
  </Card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Card from "atlas/src/components/Card/Card.vue";
import Button from "atlas/src/components/Button/Button.vue";
import { TailorMadeCardProps } from "./Props";

export default Vue.extend({
  name: "TailorMadeCard",
  components: {
    Card,
    Button,
  },
  props: {
    image: {
      type: Object as PropType<TailorMadeCardProps["image"]>,
      required: true,
    },
    url: {
      type: String as PropType<TailorMadeCardProps["url"]>,
      required: true,
    },
    description: {
      type: String as PropType<TailorMadeCardProps["description"]>,
      required: true,
    },
    label: {
      type: Array as PropType<TailorMadeCardProps["label"]>,
      required: true,
    },
    cardLayout: {
      type: String as PropType<TailorMadeCardProps["cardLayout"]>,
      required: false,
      default: "vertical",
    },
  },

  computed: {
    shouldRenderAsHorizontalCard() {
      return this.cardLayout === "horizontal";
    },
  },
});
</script>
<style lang="scss">
@import "../../components/TailorMadeCard/tailor-made-card";
</style>
