<template>
  <div v-if="url">
    <AtlasVideo :url="url" playButtonText="Play" :ssr="true" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Video from "atlas/src/components/Video/Video.vue";
import { VideoProps } from "./Props";

export default Vue.extend({
  name: "Video",
  components: {
    AtlasVideo: Video,
  },
  props: {
    url: {
      type: String as PropType<VideoProps["url"]>,
      required: false,
      default: undefined,
    },
  },
});
</script>
