<template>
  <div class="trip-snapshot" data-cy="trip-snapshot">
    <div
      class="u-background-color--sand u-padding-top--1-5 u-padding-bottom--1-5"
    >
      <div class="l-container">
        <h2
          v-if="duration || tagline"
          class="
            headline--4
            u-padding-bottom--1
            u-margin-top--0
            u-margin-bottom--0
            sm:u-text-align--center
          "
          data-cy="trip-snapshot-heading-title"
        >
          {{ duration ? `${duration} Days` : "" }}
          {{ duration && tagline ? "|" : "" }} {{ tagline }}
        </h2>

        <ReviewAggregate
          v-if="reviewAggregate && reviewAggregate.reviewsCount"
          :show-info="false"
          :average-rating="reviewAggregate.averageRating"
          :reviews-count="reviewAggregate.reviewsCount"
          class="sm:u-justify-content--center"
        />
      </div>
    </div>

    <div
      class="
        l-container
        u-padding-top--1-5
        sm:u-padding-top--1
        u-padding-bottom--1
        sm:u-padding-bottom--3
      "
    >
      <div
        class="
          l-grid
          u-justify-content--space-between
          u-margin-top--0
          u-margin-bottom--0
          u-margin-left--0
          u-margin-right--0
        "
      >
        <div
          class="
            l-grid__cell--6-col l-grid__cell--4-col-tablet
            sm:l-grid__cell--5-col-tablet
            l-grid__cell--12-col-phone
            trip-snapshot__themes
          "
        >
          <template v-if="themes">
            <Chip
              v-for="(theme, index) in themes"
              :key="index"
              class="
                chip chip--outline
                u-margin-right--0-5
                u-margin-top--0-5
                u-margin-bottom--0
              "
            >
              {{ theme.title }}
            </Chip>
          </template>
        </div>

        <div
          class="
            l-grid__cell--6-col l-grid__cell--4-col-tablet
            sm:l-grid__cell--3-col-tablet
            l-grid__cell--12-col-phone
            u-margin-top--1-5
            sm:u-margin-top--2
            md:u-margin-top--0
            u-display--flex
            u-flex-direction--column
            u-justify-content--flex-end
          "
        >
          <div
            v-if="
              fromPriceDiscount &&
              fromPriceDiscount.value &&
              fromPriceDiscount.currencyCode
            "
            class="u-text-align--left sm:u-text-align--right"
          >
            <span>From</span>
            <Price
              :value="fromPriceDiscount.value"
              :currency-code="fromPriceDiscount.currencyCode"
              :currency-locale="getLocaleByCode($i18n.locale).currencyLocale"
              :show-currency-code="true"
              :show-decimal-places="false"
              class="headline--3 u-margin-top--0 u-margin-bottom--0"
            />
          </div>

          <div
            class="u-padding-top--0-5 u-text-align--left sm:u-text-align--right"
          >
            <WishlistButton
              :product-code="productCode"
              :product-name="productName"
              class="u-display--inline"
            >
            </WishlistButton>
            <Button
              v-show="isDeparturesAvailable"
              class="
                button--primary
                u-display--none
                md:u-display--inline-flex
                u-margin-left--0-5
              "
              href="#Dates-&-availabilities"
              data-cy="trip-snapshot-dates-and-book-link"
            >
              View dates and book
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Chip from "atlas/src/components/Chip/Chip.vue";
import Price from "atlas/src/components/Price/Price.vue";
import Button from "atlas/src/components/Button/Button.vue";
import { TripSnapshotProps } from "./Props";
import WishlistButton from "~/components/WishlistButton/WishlistButton.vue";
import ReviewAggregate from "~/components/ReviewAggregate/ReviewAggregate.vue";
import { getLocaleByCode } from "~/lib/utils/locale/getLocaleByCode";

export default Vue.extend({
  name: "TripSnapshot",
  components: {
    ReviewAggregate,
    Chip,
    Price,
    Button,
    WishlistButton,
  },
  props: {
    duration: {
      type: Number as PropType<TripSnapshotProps["duration"]>,
      required: false,
      default: undefined,
    },
    tagline: {
      type: String as PropType<TripSnapshotProps["tagline"]>,
      required: false,
      default: undefined,
    },
    reviewAggregate: {
      type: Object as PropType<TripSnapshotProps["reviewAggregate"]>,
      required: false,
      default: undefined,
    },
    themes: {
      type: Array as PropType<TripSnapshotProps["themes"]>,
      required: false,
      default: undefined,
    },
    fromPriceDiscount: {
      type: Object as PropType<TripSnapshotProps["fromPriceDiscount"]>,
      required: false,
      default: undefined,
    },
    productCode: {
      type: String as PropType<TripSnapshotProps["productCode"]>,
      required: false,
      default: undefined,
    },
    productName: {
      type: String as PropType<TripSnapshotProps["productName"]>,
      required: false,
      default: undefined,
    },
  },
  methods: {
    getLocaleByCode,
    isDeparturesAvailable() {
      return !document.getElementById("Dates-&-availabilities");
    },
  },
});
</script>
<style lang="scss">
@import "./trip-snapshot";
</style>
